.main-button {
  color: white;
  font-weight: 500;
  line-height: 1.1;
  width: max-content;
  padding: 13px 22px;
  margin-right: 15px;
  /* text-transform: uppercase; */
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  font-family: "Google Sans Regular";
  display: block;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
  /* box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25), -8px -8px 15px 0 #1179f757;
   */
  box-shadow: 2px 2px 12px #ccc;
}
.main-button:hover {
  /* background-color: #ffffff; */
  /* color: black; */
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #000 !important;
  /* transition: ease-in 0.3s; */
  box-shadow: 2px 2px 12px #ccc;
}
.project-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 0px;
  }
}
@media (max-width: 480px) {
  .main-button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 10px;
  }
}
@media (max-width: 320px) {
  .main-button {
    font-size: 12px;
  }
}
