.raw_logo {
  height: 305;
  width: 440;
}
#logo {
  -webkit-animation: zoomeffect 10s infinite;
  -moz-animation: zoomeffect 10s infinite;
  animation: zoomeffect 10s infinite;
}
@media (max-width: 767px) {
  #logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);
  }
}
